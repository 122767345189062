import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import OmegaCategoryBtns from '../../components/preowned/OmegaCategoryBtns'

// markup
const BreitlingMain = () => {
  const data = useStaticQuery(
    graphql`
      query queryBreitlingMain {
        products: allStrapiProduct(
          filter: { brand: { eq: "Breitling" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Breitling Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/breguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Breitling watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/breitling/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-bretling-header.png"
                alt="Pre-Owned Certified Used Breitling Watches Header"
                aria-label="Used Breitling Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Breitling Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE BREITLING WATCHES AT GRAY AND
              SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED BREITLING WATCHES WITH GRAY
              AND SONS JEWELERS
            </h3>
            <p>
              Breitling - indestructible since 1884. There is no other Swiss manufacturer as closely
              associated with aviation as Breitling. One of the most recognizable and best-selling
              models of the manufacturer is Navtimer - the 1952 aviation watch with a chronograph.
              In addition to a variety of pilot’s watches, the Breitling range also includes solid
              diving models, such as the Avenger II Seawolf, water-resistant up to 30 bars/300
              meters, or the Superocean II model, water-resistant up to 20 bars/200 meters. Some
              other, slightly more elegant Breitling watches can be found in the Transocean
              collection.
              <br />
              <br />
              <b>Do you want to buy Breitling watches for yourself or your close ones?</b>
              <br />
              <br />
              Certified used Breitling watches at amazing prices are available now at Gray
              and Sons Jewelers. Pay less, get more - check our offer for the best prices and the
              most wanted luxury watches. Recommended by thousands of customers, the Gray and Sons
              Showroom in Bal Harbour/Miami offers the best-priced luxury watches, including
              Breitling, Rolex, Cartier, Audemars Piguet, Patek Philippe, and many others. If you
              want to buy luxury watch and save some money at the same time, Gray and Sons Jewelers
              is what you are looking for. Highly qualified team, best service, and best prices only
              at Gray and Sons Jewelers.
              <br />
              <br />
              No more waiting lists! Gray and Sons Jewelers offer several Breitling models, even the
              ones unavailable anywhere else. Every certified Breitling watch purchased at
              Gray and Sons Jewelers comes with a 1-year Gray and Sons Jewelers Warranty.
              <br />
              <br />
              <b>
                Looking for the perfect Breitling watch? See some of the most popular Breitling
                models:{' '}
              </b>
              <br />
              <br />
              <ul>
                <li>Breitling Navtimer World in Stainless Steel Ref. A2432212/B726</li>
                <li>Breitling Navtimer 1 B01 in Stainless Steel Ref. AB0121211C1P1</li>
                <li>Breitling Avenger Automatic Titanium Ref. E13360</li>
                <li>Breitling Chronomat B01 in Stainless Steel Ref. AB0115101F1A1</li>
                <li>Breitling Superocean Heritage II in Stainless Steel Ref. AB0162121G1S1</li>
                <li>Breitling Transocean Chronograph Unitime in Rose Gold Ref. RB0510U0/A733</li>
              </ul>
              <br />
              <br />
              Gray and Sons Jewelers has more than 42 years of experience in the watch and jewelry
              industry. Gray and Sons Jewelers is the top place to buy, sell, trade, repair, and
              consign used and pre-owned watches and jewelry, including estate, used, pre-owned,
              vintage, antique, and heirloom watches and jewelry. More than 42 years of experience,
              trusted and qualified team, and the best opinions.
              <br />
              <br />
              Visit our Gray and Sons Jewelers at 9595 Harding Ave Surfside, Florida, across from
              Bal Harbour Shops. Open six days a week, Monday to Saturday, with six qualified
              in-house watch experts available for our customers. Visit us online on{' '}
              <a href="/">www.grayandsons.com </a> and see the available used Breitling watches or
              other luxury watches and jewelry, including Cartier, Rolex, Patek Philippe, Bvlgari,
              and much more.
              <br />
              <br />
              <b>Buy used Breitling watches for the best price at Gray and Sons Jewelers! </b>
              <br />
              <br />
              Gray and Sons Jewelers specializes in selling luxury watches and jewelry, professional
              luxury watch repair, restoration, polishing, and cleaning. The Gray and Sons Jewelers
              customers say we are the #1 watch and jewelry store in Miami and in the U.S. If you
              want to sell your luxury watch and sell your luxury jewelry for the best price, the
              Gray and Sons Jewelers buying division, Sell Us Your Jewelry, is the top place to get
              the highest price in the shortest time. Visit{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a> and contact our
              specialist Rich to see our special selling procedure - quick, easy, and free of
              charge. <a href="/chat-with-rich">Chat with a decision-maker here. </a>
              <br />
              <br />
              <c>
                WEBSITE: <a href="/">www.grayandsons.com </a>
                &amp;
                <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a>
                <br />
                CALL: 305-770-6955. <br />
                TEXT: Vika at 786-266-4763 for a quick response. <br />
                EMAIL: sell@grayandsons.com <br /> <br />
                Gray and Sons Jewelers specializes in Pre-Owned, Estate,
                <br />
                Vintage, Antique, Heirloom, Used Watches &anp; Jewelry
                <br />
                <br />
                We Buy, Sell Trade, Consign and Repair Used and Pre-Owned Watches and Jewelry
                <br />
                <br />
                Our customers say; "There are no better certified pre-owned Watch Specialists &amp;
                Jewelers than Gray and Sons Jewelers in all of Florida and US"
                <br />
                <br />
                Visit <a href="/">www.grayandsons.com </a>
              </c>
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/other-watches-brands/breitling/">
                <button>SHOP BREITLING WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK BREITLING WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <OmegaCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default BreitlingMain
